import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; 
import { AuthService } from '@auth0/auth0-angular'; 
import { PatientEngagementService } from '../../modules/myPrimeRx/services/patient-engagement.service';
import { StateService } from '../../modules/myPrimeRx/services/history.state.service';
import { constant } from './../../constants/app.constants'
import { IdentifierTypeEnum } from '../../modules/SecurePatientCommunication/models/chat.enum';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})

export class SMSservice { 
    maskedPhoneNumber:string = "";
    elinkData:any;
    isGuestUser:boolean= false;
    elinkParams:any;
    phInfo:any;
    rxHistoryData:any;
    elinkDataString:any;
    dashbaordCounts: any = [];
    unsubscribe$: Subject<void> = new Subject();
    constructor(private _router: Router, 
        public auth:AuthService,
        public _patientEngagementService:PatientEngagementService,
        private stateService: StateService){
            this.elinkParams =  JSON.parse(localStorage.getItem('paramsData') as string);
            this.elinkData = localStorage.getItem("eLinkData")!== 'undefined' ? JSON.parse(localStorage.getItem("eLinkData") || '{}') : {}; 
            this.isGuestUser = JSON.parse(localStorage.getItem('IsGuestUser') as string);
        }

    async initSendOTP(){
        this.elinkParams =  JSON.parse(localStorage.getItem('paramsData') as string);
        this.elinkData = localStorage.getItem("eLinkData")!== 'undefined' ? JSON.parse(localStorage.getItem("eLinkData") || '{}') : {}; 
        try{
            const maskedPart = 'xxx-xxx-';
            const lastPart = this.elinkData?.PatientPhoneNo.slice(6);
            this.maskedPhoneNumber = maskedPart + lastPart;
            const payload: any = {
                "MessageTo": this.elinkData?.PatientPhoneNo,
                "npi": this.elinkParams?.npi
            }
            await this._patientEngagementService.postSendOTP(payload).subscribe(result =>{ })
        } catch (error) {
            // Handle errors here
        }
    }

    async patientPharmacyInfo(){
        try{ 
            if (this.elinkData && this.elinkParams) {
                const pharmObject: any = {
                    "npiData": this.elinkParams?.npi, 
                    "phoneNumber": this.elinkData?.PatientPhoneNo,
                    "patientNo": this.elinkData?.EntityId,
                    "pharmacyName": this.elinkData?.PharmacyName
                } 
                await this._patientEngagementService.postPatientPharmacyInfo(pharmObject).subscribe(result =>{
                    if(result)
                    {
                        this.pharmacyInfo(); 
                    }
                });
            }
        } catch (error) {
            // Handle errors here
        }
    }

    async pharmacyInfo(){
        this.phInfo = {
            "pharmacyNpi": this.elinkParams?.npi,
            "patientId": this.elinkData?.EntityId
        }
        try {
            await this._patientEngagementService.postPharmacyInfo(this.phInfo).subscribe(result =>{
                if(result){
                    localStorage.setItem('PharmacyInfo', JSON.stringify(result));
                    this.getCounts().then(() => {
                        this.handleElinkResponse(this.elinkData);
                        this._router.navigate(['/PendingPayments']); 
                    });
                }
            });
        } catch (error) {
            // Handle error appropriately
        }
    }

    handleElinkResponse(response: any) {
        const elinkMapping: { [key: string]: string } = {
            'RefillDue': constant.REFILLS_DUE,
            'Subscription': constant.SUBSCRIPTION,
            'UnSubscribe': constant.UNSUBSCRIBE,
            'General': constant.GENERAL,
            'Birthday': constant.BIRTHDAY,
            'PickUpAlert': constant.READY_TO_DISPENSE,
            'NewErx': constant.NEW_PRESSCRIPTION,
            'MobilePayment': constant.PENDING_PAYMENTS
        };

        const activeSection = elinkMapping[response.ElinkType];
        if (activeSection) {
            this.stateService.setActiveSection(activeSection);
        } else {
            console.error('Unknown ElinkType:', response.ElinkType);
        }
    }
    async getCounts() {
        await this.getDashboardgetPharmaInfo();
        this.dashbaordCounts  = await this.getDashboardCounts();
        if(this.dashbaordCounts) {
            const DboardCounts = {
            "Pending_Payments": this.dashbaordCounts?.pendingPaymentsCount,
            "My_Meds": this.dashbaordCounts?.rxHistoryCount,
            "Ready_to_Dispense": this.dashbaordCounts?.readyForPickupCount,
            "Refills_Due": this.dashbaordCounts?.refillDueRxCount,
            "New_Prescription": this.dashbaordCounts?.newErxCount 
            }
            localStorage.setItem('DboardCounts', JSON.stringify(DboardCounts)); 
        }
    }
    async getDashboardCounts() {
        this.phInfo = {
            "pharmacyNpi": this.elinkParams?.npi,
            "patientId": this.elinkData?.EntityId
        }
        try {
            const result = await this._patientEngagementService.dashboardCounts(this.phInfo).toPromise();
			if(result){
				return result;
			} 
        } catch (error) {
            // Handle error appropriately
        }
    }

    async getDashboardgetPharmaInfo() {
        this.phInfo = {
            "pharmacyNpi": this.elinkParams?.npi,
            "patientId": this.elinkData?.EntityId
        }
        try {
            const result = await this._patientEngagementService.postPharmacyInfo(this.phInfo).toPromise();
            if(result){
                localStorage.setItem('PharmacyInfo', JSON.stringify(result));
            } 
        } catch (error) {
            // Handle error appropriately
        }
    }

    async getPatientProfile() {
        this.phInfo = {
            "pharmacyNpi": this.elinkParams?.npi,
            "patientId": this.elinkData?.EntityId
        }
        try {
            const result = await this._patientEngagementService.dashboardPatientInfo(this.phInfo).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }

    async savePatientProfile(payload: any) {
        try {
            const result = await this._patientEngagementService.savePatientProfileInfo(payload).toPromise();
            if(result){
        return result;
    }
        } catch (error) {
            // Handle error appropriately
        }
    }

    async deletePatientProfile(payload: any) {
        try {
            const result = await this._patientEngagementService.deletePatientProfileInfo(payload).toPromise();
            if(result){
        return result;
    }
        } catch (error) {
            // Handle error appropriately
        }
    }
    
    async getMyMedsHistory(payload:any) {
        try {
            const result = await this._patientEngagementService.myMedsHistory(payload).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }
    
    async getReadyForPickup(payload:any) {
        try {
            const result = await this._patientEngagementService.readyForPickup(payload).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }

    async getNewErxs(payload:any) {
        try {
            const result = await this._patientEngagementService.newErxs(payload).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }

    async getRefillsDue(payload:any) {
        try {
            const result = await this._patientEngagementService.refillsDue(payload).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }

    async getAddToRefillQueue(payload:any) {
        try {
            const result = await this._patientEngagementService.addToRefillQueue(payload).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }

    async postTransaction(payload:any) {
        try {
            const result = await this._patientEngagementService.getTransaction(payload).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }

    async postUpdateSubscription(payload:any) {
        try {
            const result = await this._patientEngagementService.updateSubscription(payload).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }

    async checkUserDetailEmail(payload:any) {
        try {
            const result = await this._patientEngagementService.postUserDetailEmail(payload).toPromise();
            if(result){
				return result;
			}
        } catch (error) {
            // Handle error appropriately
        }
    }

    async getMessageUnreadCount() {
        const payload = {
            "Sender": {
                "Identifier": this.elinkParams?.npi,
                "IdentifierType": IdentifierTypeEnum.NPI
                },
            "Recipient": {
                "Identifier": ""+this.elinkData?.EntityId,
                "IdentifierType": IdentifierTypeEnum.PATIENT 
                }
        }
        try {
            const result = await this._patientEngagementService.postMessageUnreadCount(payload).toPromise(); 
            if (result) {  
                return result;
            }
            
        } catch (error) {
            // Handle error appropriately
        }
    }
    
}