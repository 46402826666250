import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  private currentPathSubject = new BehaviorSubject<string>(''); 
  currentPath$ = this.currentPathSubject.asObservable(); 

  constructor(private router: Router) {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd)  
      )
      .subscribe(() => {
        const fullUrl = this.router.url;
        const lastSegment = this.extractLastSegment(fullUrl);
        this.currentPathSubject.next(lastSegment);
      });
  }

  private extractLastSegment(url: string): string {
    const segments = url.split('/').filter(Boolean); 
    return segments.pop() || '';  
  }
}
