
import { Injectable, OnInit } from '@angular/core'; 
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { constant} from '../../../constants/app.constants';
import { AddToRefillQueueModel, PharmacyInfoModel, ProfileInfoModel, RxHistoryModel, SubscriptionType, UserDetialEmail, UserOTP } from '../models/UserOTP.model'; 
import { AuthService } from '@auth0/auth0-angular';
import { Observable, of, switchMap } from 'rxjs';
import { PatientNo, UserModel } from '../models/user.model';
import { environment } from '../../../../environments/environment';
import { SaleTransactionParams, TransactionDetail } from '../models/transactions.models';
@Injectable({
  providedIn: 'root'
})
export class PatientEngagementService  {  
  constructor(private _http:HttpClient, private _auth:AuthService) { }


  getDrugImagesBasedonNDC(payload: any) {
    return this._http.post(environment.API_BASE_URL  + constant.GET_PENDINGPAYMENTS_GETIMAGEURLS , payload);
  }

  addAccessTokenToHeaders(): Observable<HttpHeaders> {
    return this._auth.getAccessTokenSilently().pipe(
      switchMap((accessToken: string) => {
        localStorage.setItem("access_token",JSON.stringify(accessToken));
        const headers = new HttpHeaders({
          'Authorization': `Bearer ${accessToken}`
        });
        return of(headers);
      })
    );
  }

  getTransaction (payload: any) {
    return this._http.post(environment.API_BASE_URL  + constant.GET_PENDINGPAYMENTS_TRANSCATION , payload);
  }

  postSendOTP(payload: any): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.USER_SEND_OTP, payload, { headers });
      })
    );
  }

  postOTP(otpModel:UserOTP){ 
    return this._http.post<any>(environment.API_BASE_URL  + constant.USER_OTP, otpModel);
  }

  postPatientPharmacyInfo(userModel: { npiData?: string; phoneNumber?: any; patientNo?: string; pharmacyName?: any; user_metadata?: any; }) {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.USER_PATIENT_PHARMACY_INFO, userModel, { headers });
      })
    );
  }
  
  postResendOTP(otpModel:UserOTP){
    return this._http.post<any>(environment.API_BASE_URL  + constant.USER_RESEND_OTP, otpModel);
  }

  setUpTrans(payload:SaleTransactionParams){
    return this._http.post<any>(environment.API_BASE_URL  + constant.Get_SETUP_TRANS, payload);
  }

  postPharmacyInfo(payload:PharmacyInfoModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_PHARMACY_INFO, payload, { headers });
      })
    );
    }
  
  transactionDetail(payload:any){
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.get<any>(environment.API_PRIMERX_PAY_BASE_URL  + constant.POST_TRANSDETAILS_INFO + "?primerxPayTransId="+ payload?.primerxPayTransId  + "&applicationTransId=" + payload?.ApplicationTransId + "&transSetupID=" + payload?.TransSetupID + "&applicationId=7&paymentProviderId=1" , {headers});
      })
    );
  }

  postUserDetails(userModel:PatientNo){
      return this._http.post<any>(environment.API_BASE_URL  + constant.POST_USER_DETAILS, userModel); 
  }

  /* /Dashboard/DashboardCounts */

  dashboardCounts(payload:PharmacyInfoModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_DASHBOARD_COUNTS, payload, { headers });
      })
    );
  }

  dashboardPatientInfo(payload:PharmacyInfoModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_DASHBOARD_PATIENT_INFO, payload, { headers });
      })
    );
  }

  savePatientProfileInfo(payload:ProfileInfoModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_PROFILE_UPDATE, payload, { headers });
      })
    );
  }

  deletePatientProfileInfo(payload:ProfileInfoModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.DELETE_PROFILE_PICTURE, payload, { headers });
      })
    );
  }
  
  myMedsHistory(payload:RxHistoryModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_MYMEDS_HISTORY, payload, { headers });
      })
    );
  }

  readyForPickup(payload:PharmacyInfoModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_READY_FOR_PICKUP, payload, { headers });
      })
    );
  }
  
  newErxs(payload:PharmacyInfoModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_NEW_ERXS, payload, { headers });
      })
    );
  }

  refillsDue(payload:PharmacyInfoModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_GET_REFILLS_DUE, payload, { headers });
      })
    );
  }

  addToRefillQueue(payload:AddToRefillQueueModel): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_ADD_REFILLS_QUE, payload, { headers });
      })
    );
  }

  updateSubscription(payload:SubscriptionType){ 
        return this._http.post<any>(environment.API_BASE_URL  + constant.UPDATE_SUBSCRIPTION, payload); 
  }
  
  postUserDetailEmail(payload:UserDetialEmail): Observable<any> {
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.post<any>(environment.API_BASE_URL  + constant.POST_USER_DETAIL_EMAIL, payload, { headers });
      })
    );
  }

  
  chatInvoke(patientId:any){
    return this.addAccessTokenToHeaders().pipe(
      switchMap(headers => {
        return this._http.get<any>(environment.Negotiate_apiBaseUrl  + `/${constant.NEGOTIAGE}?userid=${patientId}`, {headers});
      })
    );
  }

  postConversation(payload:any): Observable<any> {
    const reqOptns = {
        headers: new HttpHeaders().set("isSPCBaseUrl", "true")
    };
    return this._http.post(constant.POST_CONVERSATION, payload, reqOptns);      
  }

  postMessageMarkAsRead(data: any) {
      const reqOptns = {
          headers: new HttpHeaders().set("isSPCNoProxyBaseUrl", "true")
      };
      return this._http.post(constant.POST_MESSAGE_MARKASREAD , data, reqOptns);
  }

  postMessageUnreadCount(data: any) {
      const reqOptns = {
          headers: new HttpHeaders().set("isSPCNoProxyBaseUrl", "true")
      };
      return this._http.post(constant.POST_MESSAGE_UNREADCOUNT , data, reqOptns);
  }
  
}
