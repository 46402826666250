import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { WebPubSubService } from './WebPubSubService';
import { SMSservice } from '../core/services/sms.service'; 
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonMessageService {
  unreadMsgCount:any;
  private unreadCountSubject = new BehaviorSubject<number>(0);
  unreadCount$ = this.unreadCountSubject.asObservable();
  isSecurePatientComm!:boolean;
  private unsubscribe$ = new Subject<void>(); 

  constructor(
    private webPubSubService: WebPubSubService,
    private _smsService: SMSservice 
  ) {
    this.initializeMessageListener();
    this.isSecurePatientComm = environment.isSecurePatientComm;
  }

  private initializeMessageListener(): void {
    this.webPubSubService.newMessage$.subscribe(() => { 
      this.getCounts(); 
    });
  }

  async getCounts(): Promise<void> {
    try {
      if (this.isSecurePatientComm) {
        this.unreadMsgCount = await this._smsService.getMessageUnreadCount();
        const unreadCount = this.unreadMsgCount?.unreadCount && this.unreadMsgCount.unreadCount > 0
            ? this.unreadMsgCount.unreadCount
            : 0; 
        this.unreadCountSubject.next(unreadCount);
      }
    } catch (error) {
      console.error('Error fetching unread message count:', error);
      this.unreadCountSubject.next(0);
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
